.hero-bg {
  position: relative;
  width: 100%;
  background-image: url("./assets/images/webp/hero-bg.webp") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 40px !important;
}

.top-bar-container {
  padding-top: 140px;
  margin-top: -130px;
}

.hero-bgs::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/webp/hero-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  transform-origin: top left;
  background-attachment: fixed;
  transform: scale(1);
}

.main-container {
  background-image: none;
  /* Or simply remove the property */
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-bg::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/webp/footer-bg.webp");
  background-size: 100% 77.7%;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: -1;
}

.discover-bg::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/webp/discover-bg.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  border-radius: 32px;
}

.bounce-button {
  position: fixed;
  animation: bounce 1.5s infinite;
}

.custom-gap {
  gap: 2px;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 1280px) {
  .footer-bg::before {
    background-size: 100% 82.5%;
  }

  .hero-bg {
    margin-top: 0 !important;
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 844px) {
  .top-bar-container {
    padding-top: 140px;
    margin-top: -80px;
  }

  .footer-bg::before {
    background-size: 100% 85.3%;
  }

  .discover-bg::before {
    border-radius: 10px;
  }

  .hero-bg::before {
    height: 50%;
  }

  .hero-bg {
    margin-top: 0 !important;
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px) {
  .footer-bg::before {
    background-size: 100% 85.3%;
  }

  .discover-bg::before {
    border-radius: 10px;
  }

  .hero-bg::before {
    height: 60%;
  }

  .hero-bg {
    margin-top: 0 !important;
    margin-bottom: 30px !important;
  }

  /* .top-bar-container {
    padding-top: 180px;
    margin-top: -169px;
  } */

  .Watch-Now {
    height: 42px;
    width: 43px;
    margin-top: -121px;
  }
}

@media (max-width: 1024px) and (max-height: 1366px) {
  .hero-bg {
    /* background-size: 60% !important; */
  }

  .custom-gap {
    gap: 2px !important;
  }
}



@media (max-width: 414px) and (max-height: 896px) {

  h4.font-light,
  p.font-bold {
    font-size: 16px !important;
  }

  .custom-gap {
    gap: 4px !important;
  }

  button.close-button {
    top: 18px !important;
    right: 16px !important;
    font-size: 22px !important;
    padding: 4px !important;
  }

  .Watch-Now {
    height: 42px;
    width: 43px;
    margin-top: -121px;
  }

  .top-bar-container {
    padding-top: 140px;
    margin-top: -130px;
  }
}

@media (max-width: 430px) and (max-height: 932px) {
  .Watch-Now {
    height: 42px;
    width: 43px;
    margin-top: -121px;
  }

  .top-bar-container {
    padding-top: 140px;
    margin-top: -130px;
  }
}

@media (max-width: 390px) and (max-height: 844px) {

  h4.font-light,
  p.font-bold {
    font-size: 15px !important;
  }

  .custom-gap {
    gap: 3px !important;
  }

  button.close-button {
    top: 20px !important;
    right: 18px !important;
    font-size: 22px !important;
    padding: 4px !important;
  }
}

@media (max-width: 360px) and (max-height: 740px) {

  h4.font-light,
  p.font-bold {
    font-size: 14px !important;
  }

  .custom-gap {
    gap: 2px !important;
  }

  button.close-button {
    top: 16px !important;
    right: 14px !important;
    font-size: 20px !important;
    padding: 3px !important;
  }
}

@media (max-width: 768px) {
  .overflow-auto {
    overflow: auto;
  }

  button.close-button {
    top: 380px !important;
    right: 16px !important;
    font-size: 22px !important;
    padding: 4px !important;
  }

  .top-bar-container {
    padding-top: 140px;
    margin-top: -130px;
  }
}

@media (max-width: 912px) and (max-height: 1368px) {
  button.close-button {
    top: 358px !important;
    right: 50px !important;
    font-size: 22px !important;
    padding: 4px !important;
  }
}

.close-button {
  position: absolute;
  top: 24px;
  right: 56px;
  font-size: 2.25rem;
  padding: 1rem;
  color: #3B82F6;
  font-weight: bold;
  z-index: 50;
}

.close-button-1 {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 20px;
  padding: 10px;
  z-index: 50;
  background: transparent;
  border: none;
  cursor: pointer;
}

@media (max-width: 375px) and (max-height: 667px) {
  .close-button-1 {
    top: 22px !important;
    right: 20px !important;
    font-size: 20px !important;
    padding: 4px !important;
  }
}

@media (max-width: 412px) and (max-height: 915px) {
  .close-button-1 {
    top: 22px !important;
    right: 20px !important;
    font-size: 25px !important;
    padding: 6px !important;
  }
}

@media (max-width: 414px) and (max-height: 896px) {
  .close-button-1 {
    top: 22px !important;
    right: 20px !important;
    font-size: 25px !important;
    padding: 6px !important;
  }
}

@media (max-width: 430px) and (max-height: 932px) {
  .close-button-1 {
    top: 22px !important;
    right: 20px !important;
    font-size: 25px !important;
    padding: 6px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .close-button-1 {
    top: 150px;
    right: 20px;
    font-size: 1.875rem !important;
    padding: 0.5rem !important;
  }

  .Watch-Now {
    height: 42px;
    width: 43px;
    margin-top: 70px;
  }

  .top-bar-container {
    padding-top: 200px;
    margin-top: -200px;
  }
}






/* Small Devices: Mobile phones (Portrait) */
@media (max-width: 767px) {
  .shaping-Img {
    margin-top: -56px;
    margin-bottom: 40px;
  }

  .future-Icon {
    margin-right: 19px;
    margin-top: 20px;
    height: 90px;

  }

  .Watch-Now {
    margin-top: 20px;
  }


}


@media (min-width: 768px) and (max-width: 1023px) {
  .shaping-Img {
    margin-top: -60px;
    margin-bottom: 50px;
  }

  .Watch-Now {
    margin-top: 25px;
  }


  .top-bar-container {
    padding-top: 260px !important;
  }

  .future-Icon {
    margin-right: 9px;
    margin-top: 18px;
    height: 112px;
  }
}


@media (min-width: 1024px) {
  .top-bar-container {
    padding-top: 287px;
  }
}


@media (min-width: 1024px) and (max-width: 1326px) {
  .top-bar-container {
    padding-top: 274px !important;
  }
}



@media (width: 912px) and (height: 1368px) {

  .hero-sub-heading {
    margin-left: 66px;
  }
}

@media (max-width: 430px) and (max-height: 932px) {
  .hero-sub-heading {
    font-size: calc(1rem + 2px);
  }
}



@media (min-width: 375px) and (max-width: 667px) {
  .Certified27001 {
    margin-top: -50px;
  }
}


@media (min-width: 340px) and (max-width: 360px) {
  .Certified27001 {
    margin-top: -62px;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .Certified27001 {
    margin-top: -55px;
  }
}



@media (width: 820px) and (height: 1180px) {
   .hero-sub-heading{
    margin-left: 20px;
   }
}


@media (width: 853px) and (height: 1280px) {
  .hero-sub-heading{
   margin-left: 38px;
  }
}