@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Satoshi", sans-serif !important;
}

@layer components {
  /* CONTAINER */
  .main-container {
    @apply max-w-[1352px] w-full mx-auto px-4;
  }

  /* HERO-HEADING */
  .hero-heading {
    @apply 3xl:text-[72px] lg:text-[60px] md:text-5xl xs:text-[40px] text-3xl font-black leading-[110%] tracking-[-0.72px] text-center text-white;
  }
  .hero-sub-heading {
    @apply md:text-xl text-base leading-[160%] tracking-[0.1px] font-medium text-center text-white;
  }

  /* BUTTONS */
  .primary-button {
    @apply px-7 3xl:h-[52px] 1xl:h-12 h-10 1xl:text-lg text-base tracking-[-0.18px] text-white font-medium bg-candy rounded-[35px] flex items-center justify-center w-full  transition-all duration-200 ease-in-out hover:shadow-md hover:shadow-candy hover:-translate-y-1;
  }
  .secondary-button {
    @apply px-7 3xl:h-[52px] 1xl:h-12 h-10 1xl:text-lg text-base text-white font-medium bg-candy rounded-[35px] border-[1.5px] border-lavender-haze bg-mist-white backdrop-blur-[8px] flex items-center justify-center transition-all duration-200 ease-in-out hover:bg-transparent hover:-translate-y-1;
  }

  /* HEADINGS */
  .heading {
    @apply xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold leading-[115%];
  }
  .sub-heading {
    @apply xl:text-lg lg:text-base text-sm leading-[160%] text-primary;
  }

  /* UL-HOVER */
  .footer-li-hover {
    @apply absolute   left-1/2 -translate-x-1/2 bottom-0 h-0.5 bg-baby w-0 group-hover:w-full transition-all duration-300 rounded-full;
  }
  .footer-li-text {
    @apply text-light-gray text-base sm:text-lg leading-[100%] relative inline-block hover:text-baby duration-200 ease-in-out transition-all;
  }
}

/* CUSTOM-SCROLLBAR */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #080b29;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555a65;
}
/* .primary-button::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: theme("colors.candy");
  z-index: -1;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.3s ease-out;
} */

/* .primary-button:hover::before {
  transform: scaleX(1);
} */
/* .secondary-button::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: theme("colors.mist-white");
  z-index: -1;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.3s ease-out;
}

.secondary-button:hover::before {
  transform: scaleX(1);
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
